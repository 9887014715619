import { graphql } from "gatsby";
import React from "react";
import Layout from "../../components/layout";
import { SEO } from "../../components/seo";
import HeroDigitalMarketing from "../../components/ServiceSinglePage/Hero/DigitalMarketing/HeroDigitalMarketing";
import ServiceIconBlock from "../../components/ServiceSinglePage/ServiceIcon/ServiceIconBlock";
import ServiceCTA from "../../components/ServiceLanding/CTA/ServiceCTA";
import LatestBlogs from "../../components/LatestBlogs/LatestBlogs";
import HeroUIUX from "../../components/ServiceSinglePage/Hero/UIUX/HeroUIUX";
import HeroSeo from "../../components/ServiceSinglePage/Hero/SEO/HeroSeo";
import FAQ from "../../components/FAQ/FAQ";
import faqs from "../../data/faq.json";
import { Helmet } from "react-helmet";
import localisationData from '../../data/localisation.json';
import ThreeColumnContent from "../../components/ThreeColumnContent";

function SEOPage({ location }) {
    const heroTextTitle = "SEO Agency Saigon";
    const heroTextSmallText =
        "Dominate search rankings with Saigon Digital, the leading SEO Agency in Saigon. Our expert SEO services ensure your business stands out online, driving organic traffic and boosting conversions.";
    const ctaTitle = "Achieve Unparalleled Visibility with Saigon's #1 SEO Agency";
    const heroData = [
        "At Saigon Digital, we specialize in SEO strategies tailored to businesses in Vietnam and beyond. From comprehensive keyword research to technical SEO and content optimization, our proven methods elevate your brand in search results.",
        "Partner with Saigon Digital for measurable results, including higher rankings, increased website traffic, and enhanced engagement. Let us transform your digital presence and secure your position as a market leader."
    ];

    const strategyIcons = [
        {
            name: "computer-eye",
            title: "Dominate Visibility",
            text: "Climbing to the top of search rankings means unparalleled exposure for your business in Saigon and across Vietnam."
        },
        {
            name: "traffic",
            title: "Drive Quality Traffic",
            text: "Our strategies bring highly relevant traffic to your website, maximizing your return on investment."
        },
        {
            name: "badge",
            title: "Build Credibility",
            text: "Ranking high instills trust in your audience. Showcase your reliability with a strong online presence."
        }
    ];

    const designIcons = [
        {
            name: "keyword",
            title: "In-Depth Keyword Research",
            text: "Identify the terms your audience is searching for and align your content to meet their needs."
        },
        {
            name: "optimize",
            title: "On-Page Optimization",
            text: "Fine-tune every element of your site for maximum search engine friendliness."
        },
        {
            name: "content-creator",
            title: "Content Strategy & Creation",
            text: "Engage your audience with valuable, SEO-optimized content tailored to Saigon’s market."
        },
        {
            name: "loop",
            title: "Technical SEO Mastery",
            text: "Ensure your site meets the highest technical standards for speed, security, and mobile optimization."
        },
        {
            name: "link",
            title: "Backlink Building",
            text: "Earn authority through strategic link-building campaigns that position your site as a trusted resource."
        },
        {
            name: "computer-location",
            title: "Local SEO",
            text: "Capture local audiences in Saigon with strategies tailored to your community and market."
        }
    ];

    const context = {
        pageName: "SEO Agency Saigon",
        pageUri: location.href
    };

    return (
        <Layout context={context}>
            <HeroSeo
                heroTextTitle={heroTextTitle}
                heroTextSmallText={heroTextSmallText}
                ctaTitle={ctaTitle}
                heroData={heroData}
            />
            <div className="[&_.service-icon]:pb-0 lg:[&_.service-icon]:pt-40 [&_.wave-bottom]:hidden">
                <ServiceIconBlock
                    title={"Why Choose Saigon Digital?"}
                    icons={strategyIcons}
                />
            </div>
            <div className="[&_.service-icon]:py-[120px] [&_.wave-bottom]:hidden [&_.wave-top]:hidden">
                <ServiceIconBlock
                    title={"Our Comprehensive SEO Services"}
                    icons={designIcons}
                />
            </div>
            <ThreeColumnContent localisationData={localisationData.seo}/>
            <div className="mt-36">
                <ServiceCTA title={ctaTitle} />
            </div>
            <div className="container mt-10 lg:mt-32">
                <FAQ faqs={faqs.seoFaqs} />
            </div>
            <LatestBlogs/>
        </Layout>
    );
}

export default SEOPage;
export const Head = () => (
    <>
        <Helmet>
            <script type="application/ld+json">
                {JSON.stringify([
                    {
                        "@context": "https://schema.org",
                        "@type": "Service",
                        "name": "SEO Agency Saigon - Saigon Digital",
                        "description": "Professional SEO agency services tailored for businesses in Saigon, Vietnam. Improve your search rankings and drive organic growth with Saigon Digital.",
                        "url": "https://saigon.digital/services/seo/",
                        "provider": {
                            "@type": "Organization",
                            "name": "Saigon Digital",
                            "url": "https://saigon.digital/",
                            "logo": "https://saigon.digital/sd-logo.png",
                            "contactPoint": {
                                "@type": "ContactPoint",
                                "telephone": "+84 332 364 293",
                                "contactType": "Customer Service",
                                "areaServed": "VN",
                                "availableLanguage": ["English", "Vietnamese"]
                            }
                        },
                        "serviceType": "SEO Agency Services",
                        "areaServed": {
                            "@type": "Place",
                            "name": "Saigon, Vietnam"
                        },
                        "priceRange": "$$$",
                        "review": {
                            "@type": "Review",
                            "reviewRating": {
                                "@type": "Rating",
                                "ratingValue": "5",
                                "bestRating": "5"
                            },
                            "author": {
                                "@type": "Person",
                                "name": "Minh Nguyen"
                            }
                        }
                    },
                    {
                        "@context": "https://schema.org",
                        "@type": "FAQPage",
                        "mainEntity": [
                            {
                                "@type": "Question",
                                "name": "Why is Saigon Digital the top SEO Agency in Saigon?",
                                "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "Our data-driven approach, expertise in the Saigon market, and proven success record make us the go-to SEO agency in Vietnam."
                                }
                            },
                            {
                                "@type": "Question",
                                "name": "What makes SEO essential for Saigon businesses?",
                                "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "SEO helps businesses in Saigon increase visibility, attract local customers, and build a strong online presence."
                                }
                            }
                        ]
                    }
                ])}
            </script>
        </Helmet>
        <SEO
            title="SEO Agency Saigon | Saigon Digital"
            description="Partner with Saigon Digital, the #1 SEO agency in Saigon, for expert strategies that boost rankings and drive growth."
        />
    </>
);
