import React from "react"
import Layout from "../components/layout"
import Hero from "../components/Hero/Hero"
import { SEO } from "../components/seo"
import Blob from "../components/ServiceLanding/Hero/Blob"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Linkedin from "../components/Icons/Linkedin"
import Email from "../components/Icons/Email"

function Contact({ location }) {
    const context = {
        pageName: "SD | Contact",
        pageUri: location.href
    }
    return (
        <Layout context={context}>
            {/* HERO */}
            <section className="relative isolate grid overflow-x-clip pb-[100px] lg:h-[calc(100vh-100px)] lg:pb-0">
                <div className="absolute top-1/2 left-1/2 z-[-1] hidden w-full -translate-x-1/2 -translate-y-1/2 lg:left-0 lg:top-[-20%] lg:block lg:max-w-7xl lg:translate-y-0 lg:-translate-x-1/3">
                    <Blob />
                </div>

                <div className="container mt-[10vh] grid items-center lg:mt-0 lg:grid-cols-2 lg:gap-x-[30px]">
                    <div className="mx-auto mb-0 max-w-xl text-center lg:mr-auto lg:mb-[100px] lg:text-left">
                        <span className="mb-3 block text-2xl font-semibold text-primary-color lg:text-3xl">
                            Contact Us
                        </span>
                        <h1 className="page-title font-bold !leading-[1.4]">
                            Start your next project
                        </h1>
                        <a class="mx-auto mt-10 max-w-max md:mx-0" target="_blank" href="https://calendly.com/nick-saigon-digital/meetng-with-nicholas-rowe?back=1&amp;month=2025-01"><button type="button" class="button group inline-flex items-center gap-x-2 gray-btn ">Book a Free Consultation</button></a>
                    </div>

                    <div className="grid grid-cols-2 items-center gap-x-[30px]">
                        <div>
                            <StaticImage
                                loading="eager"
                                src="../images/contact/nick-on-contact.png"
                                objectFit="contain"
                            />
                            <div className="flex flex-col flex-wrap justify-between gap-2 rounded-2xl rounded-tl-[0] bg-[#0044AE] px-4 pt-3 pb-4 text-white lg:ml-6 lg:flex-row lg:px-6">
                                <div>
                                    <h2 className="text-sm font-semibold lg:mb-1.5">
                                        Nick Rowe
                                    </h2>
                                    <h3 className="text-xs">
                                        CEO & Co-Founder
                                    </h3>
                                </div>
                                <div className="flex items-center gap-x-1">
                                    <Link
                                        to="https://www.linkedin.com/in/nicholas-rowe-28a1524b/"
                                        target="_blank"
                                        className="w-5"
                                    >
                                        <Linkedin />
                                    </Link>

                                    <Link
                                        to="mailto:nick@saigon.digital"
                                        className="w-6"
                                        target="_blank"
                                    >
                                        <Email />
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="translate-y-[93px]">
                            <div className="aspect-[146/93] h-[93px]">
                                <StaticImage
                                    loading="eager"
                                    src="../images/contact/spotlight-logo.png"
                                    objectFit="contain"
                                    className="h-full w-full -translate-x-[30px]"
                                />
                            </div>

                            <StaticImage
                                loading="eager"
                                src="../images/contact/jonas-on-contact.png"
                                objectFit="contain"
                            />
                            <div className="flex flex-col flex-wrap justify-between gap-2 rounded-2xl rounded-tl-[0] bg-primary-color px-4 pt-3 pb-4 text-white lg:ml-6 lg:flex-row lg:px-6">
                                <div>
                                    <h2 className="mb-1.5 text-sm font-semibold">
                                        Jonas Hoener
                                    </h2>
                                    <h3 className="text-xs">
                                        COO & Co-Founder
                                    </h3>
                                </div>

                                <div className="flex items-center gap-x-1">
                                    <Link
                                        to="https://www.linkedin.com/in/jonas-hoener-78a870179/"
                                        target="_blank"
                                        className="w-5"
                                    >
                                        <Linkedin />
                                    </Link>

                                    <Link
                                        to="mailto:jonas@saigon.digital"
                                        className="w-6"
                                        target="_blank"
                                    >
                                        <Email />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}
export default Contact
export const Head = () => (
    <SEO
        title={"Contact | Saigon Digital"}
        description={
            "We're here to listen, understand your business and deliver technical solutions to push you forward in the digital space."
        }
    />
)
