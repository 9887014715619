import { graphql } from "gatsby";
import React from "react";
import Layout from "../../components/layout";
import { SEO } from "../../components/seo";
import HeroDigitalMarketing from "../../components/ServiceSinglePage/Hero/DigitalMarketing/HeroDigitalMarketing";
import ServiceIconBlock from "../../components/ServiceSinglePage/ServiceIcon/ServiceIconBlock";
import ServiceCTA from "../../components/ServiceLanding/CTA/ServiceCTA";
import LatestBlogs from "../../components/LatestBlogs/LatestBlogs";
import HeroUIUX from "../../components/ServiceSinglePage/Hero/UIUX/HeroUIUX";
import FAQ from "../../components/FAQ/FAQ";
import faqs from "../../data/faq.json";
import { Helmet } from "react-helmet";
import localisationData from '../../data/localisation.json';
import ThreeColumnContent from "../../components/ThreeColumnContent";

function UIUXPage({ location }) {
    const heroTextTitle = "Web Design Agency Saigon";
    const heroTextSmallText =
        "Transform your brand with Saigon Digital, the premier web design agency in Saigon. We deliver visually stunning and highly functional web designs that captivate audiences and drive results.";
    const ctaTitle = "Partner with the Leading Web Design Agency in Saigon!";
    const heroData = [
        "At Saigon Digital, we specialize in creating bespoke web designs tailored for businesses in Saigon and beyond. Our team blends creative innovation with technical expertise to build websites that engage and convert.",
        "We understand that exceptional UI/UX design is essential to showcase your brand effectively online. From mobile to desktop, we craft user-centric designs that ensure seamless digital experiences, elevating your business above the competition.",
        "Whether you're a startup, small business, or established enterprise, our web design services are tailored to meet your unique needs. Let us help you establish a strong online presence with a website that delivers impact and results.",
        "Get a FREE quote today and see how Saigon Digital can elevate your online presence, making meaningful connections between your brand and audience."
    ];

    const strategyIcons = [
        {
            name: "checklist",
            title: "Strategic Analysis",
            bulletPoints: [
                "Gathering functional requirements",
                "Assessing business objectives",
                "Choosing the right tech stack",
                "Conducting competitive research",
                "Identifying target audience groups"
            ]
        },
        {
            name: "search",
            title: "User Research",
            bulletPoints: [
                "Interviewing potential audiences",
                "Evaluating user expectations",
                "Analyzing behaviors and attitudes",
                "Creating user personas",
                "Strategizing solutions"
            ]
        },
        {
            name: "dart",
            title: "Creative Ideation",
            bulletPoints: [
                "Developing information architecture",
                "Conceptualizing UX designs",
                "Creating storyboards",
                "Designing user flows",
                "Structuring navigation or site maps"
            ]
        }
    ];

    const designIcons = [
        {
            name: "checklist",
            title: "Custom Web Design",
            bulletPoints: [
                "User-focused UX design",
                "Modern visual/UI design",
                "Interactive UI motion design",
                "Developing UI libraries and design systems"
            ]
        },
        {
            name: "dart",
            title: "Accessibility First",
            bulletPoints: [
                "Optimizing UI/UX content",
                "Copywriting for UX",
                "Custom illustrations",
                "Graphics tailored to your audience"
            ]
        }
    ];

    const consultIcons = [
        {
            name: "checklist",
            title: "Comprehensive UI/UX Audits",
            bulletPoints: [
                "Accessibility evaluations",
                "Scalability analysis",
                "Localisation strategies",
                "UI/UX performance testing",
                "Internal usability testing"
            ]
        },
        {
            name: "search",
            title: "Data-Driven Research",
            bulletPoints: [
                "Analyzing Google Analytics",
                "A/B testing for insights",
                "Conducting live user testing",
                "Launching beta solutions"
            ]
        },
        {
            name: "dart",
            title: "UI/UX Strategy Enhancement",
            bulletPoints: [
                "Analyzing user behavior",
                "Delivering usability reports",
                "Providing post-launch analytics",
                "Integrating updates and enhancements"
            ]
        }
    ];

    const cta2 = {
        title: "Your Website Deserves to Stand Out. Let's Create Something Amazing!",
        ctaText: "Get Started Now"
    };

    const context = {
        pageName: "Service | Web Design Agency Saigon",
        pageUri: location.href
    };

    return (
        <Layout context={context}>
            <HeroUIUX
                heroTextTitle={heroTextTitle}
                heroTextSmallText={heroTextSmallText}
                ctaTitle={ctaTitle}
                heroData={heroData}
            />
            <div className="[&_.service-icon]:pb-0 lg:[&_.service-icon]:pt-40 [&_.wave-bottom]:hidden">
                <ServiceIconBlock
                    title={"Strategic Planning"}
                    icons={strategyIcons}
                />
            </div>
            <div className="[&_.service-icon]:py-[120px] [&_.wave-bottom]:hidden [&_.wave-top]:hidden">
                <ServiceIconBlock
                    title={"Design Excellence"}
                    icons={designIcons}
                />
            </div>
            <div className="[&_.service-icon]:pt-0 lg:[&_.service-icon]:pb-40 [&_.wave-top]:hidden">
                <ServiceIconBlock
                    title={"Expert Consulting"}
                    icons={consultIcons}
                />
            </div>
            <div className="mt-36">
                <ServiceCTA {...cta2} />
            </div>
            <ThreeColumnContent localisationData={localisationData.webdesign}/>

            <div className="container mt-10 lg:mt-32">
                <FAQ faqs={faqs.webDesignFaqs} />
            </div>
            <LatestBlogs />
        </Layout>
    );
}

export default UIUXPage;
export const Head = () => (
    <>
        <Helmet>
            <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "FAQPage",
                    mainEntity: faqs.webDesignFaqs.map(faq => ({
                        "@type": "Question",
                        name: faq?.question,
                        acceptedAnswer: {
                            "@type": "Answer",
                            text: faq?.answer[0]
                        }
                    }))
                })}
            </script>
        </Helmet>
        <SEO
            title="Web Design Agency Saigon | Saigon Digital"
            description="Discover Saigon Digital, the leading web design agency in Saigon. Our expert services create stunning, user-friendly websites tailored to your needs."
        />
    </>
);
