import React from "react";
import LatestBlogs from "../../components/LatestBlogs/LatestBlogs";
import Layout from "../../components/layout";
import ServiceIconBlock from "../../components/ServiceSinglePage/ServiceIcon/ServiceIconBlock";
import TextImageJamStack from "../../components/ServiceSinglePage/TextImage/TextImageJamStack";
import ServiceCTA from "../../components/ServiceLanding/CTA/ServiceCTA";
import HeroWordpress from "../../components/ServiceSinglePage/Hero/Wordpress/HeroWordpress";
import { SEO } from "../../components/seo";
import { StaticImage } from "gatsby-plugin-image";
import FAQ from "../../components/FAQ/FAQ";
import faqs from "../../data/faq.json";
import { Helmet } from "react-helmet";
import localisationData from '../../data/localisation.json';
import ThreeColumnContent from "../../components/ThreeColumnContent";

const heroTextTitle = "Web Development Agency Saigon";
const heroTextSmallText =
    "As the leading web development agency in Saigon, Saigon Digital delivers impactful web solutions tailored to elevate your brand and drive business growth.";
const ctaTitle = "Partner with Saigon's Premier Web Development Agency";
const heroData = [
    "Saigon Digital is your trusted web development agency in Saigon, providing innovative solutions for businesses of all sizes. Our team leverages the latest technologies to create custom websites that are both functional and visually engaging.",
    "With years of expertise, we specialize in diverse projects, including e-commerce stores, customer-facing apps, and internal portals. We ensure a transparent, efficient development process to meet the highest quality standards.",
    "Our services range from open-source technologies like PHP and MySQL to advanced frameworks such as ASP.NET and SQL Server. We also offer comprehensive CMS solutions, including WordPress and SugarCRM.",
    "Every project is tailored to your unique needs, ensuring a bespoke web development solution that exceeds expectations.",
    "Choose Saigon Digital for reliable, cutting-edge web development services that help your business thrive in the digital age."
];
const iconBlockTitle = "Comprehensive Web Development Services for Saigon Businesses";
const icons = [
    {
        name: "check",
        title: "Frontend Development",
        text: "We craft dynamic user interfaces using modern frameworks like React, Vue, and Angular, delivering engaging digital experiences."
    },
    {
        name: "performance",
        title: "Backend Development",
        text: "Our backend solutions ensure robust, scalable infrastructures using technologies such as Node.js, Python, and Ruby on Rails."
    },
    {
        name: "chart",
        title: "Full Stack Development",
        text: "We offer end-to-end web development solutions, seamlessly integrating frontend and backend to meet your business goals."
    },
    {
        name: "content",
        title: "Low-Code Development",
        text: "Leverage platforms like Webflow and Mendix to create efficient web solutions, saving time and reducing costs."
    },
    {
        name: "bolt",
        title: "Cloud Development",
        text: "Deploy secure, scalable applications on leading cloud platforms, including AWS, Google Cloud, and Azure."
    },
    {
        name: "content",
        title: "Website Security Audits",
        text: "Ensure your website's safety and performance with our in-depth security audits and optimization services."
    }
];
const cta1 = {
    title: "Tailored Web Solutions for Your Business",
    description:
        "At Saigon Digital, we prioritize innovation and efficiency, continuously enhancing our development processes to deliver seamless, future-proof solutions. From concept to maintenance, our developers are committed to exceeding your expectations."
};
const cta2 = {
    title: "Transform Your Online Presence with Saigon's Best Web Development Agency.",
    ctaText: "Get Started Today"
};

const Wordpress = ({ location }) => {
    const context = {
        pageName: "Service | Web Development Agency Saigon",
        pageUri: location.href
    };
    return (
        <Layout context={context}>
            <HeroWordpress
                heroTextTitle={heroTextTitle}
                heroTextSmallText={heroTextSmallText}
                ctaTitle={ctaTitle}
                heroData={heroData}
            />
            <ServiceIconBlock title={iconBlockTitle} icons={icons} />
            <TextImageJamStack
                title={cta1.title}
                description={cta1.description}
            >
                <StaticImage
                    alt="web development agency saigon"
                    src="../../components/ServiceSinglePage/images/wordpress-text-image.svg"
                />
            </TextImageJamStack>
            <div className="pt-20 md:pt-0">
                <ServiceCTA title={cta2.title} ctaText={cta2.ctaText} />
            </div>
            <ThreeColumnContent localisationData={localisationData.webdev}/>
            <div className="container mt-10 lg:mt-32">
                <FAQ faqs={faqs.webDevelopmentFaqs} />
            </div>
            <LatestBlogs />
        </Layout>
    );
};

export default Wordpress;
export const Head = () => (
    <>
        <Helmet>
            <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "FAQPage",
                    mainEntity: faqs.webDevelopmentFaqs.map(faq => ({
                        "@type": "Question",
                        name: faq?.question,
                        acceptedAnswer: {
                            "@type": "Answer",
                            text: faq?.answer[0]
                        }
                    }))
                })}
            </script>
        </Helmet>
        <SEO
            title={"Web Development Agency Saigon | Saigon Digital"}
            description={
                "Saigon Digital, the top web development agency in Saigon, offers innovative solutions to help your business grow online."
            }
        />
    </>
);
