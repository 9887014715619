import React from "react"
import Layout from "../../components/layout"
import HeroDigitalTransform from "./../../components/ServiceSinglePage/Hero/Digital/HeroDigitalTransform"
import ServiceIconBlock from "../../components/ServiceSinglePage/ServiceIcon/ServiceIconBlock"
import TextImageJamStack from "../../components/ServiceSinglePage/TextImage/TextImageJamStack"
import FAQ from "../../components/FAQ/FAQ"
import faqs from "../../data/faq.json";
import LatestBlogs from "../../components/LatestBlogs/LatestBlogs"
import { SEO } from "../../components/seo"
import { Helmet } from "react-helmet"
import localisationData from '../../data/localisation.json'
import ThreeColumnContent from "../../components/ThreeColumnContent"

const heroTextTitle = "Leading Saigon Marketing Agency"
const heroTextSmallText =
    "Looking for a results-driven marketing agency in Saigon? We specialize in SEO, digital marketing, and innovative strategies to grow your business."

const heroData = [
    "Saigon Digital is your partner for scaling businesses in Vietnam. With expertise in SEO, digital transformation, and content marketing, we deliver tailored solutions to help you achieve your growth goals.",
    "As a leading marketing agency in Ho Chi Minh City, we empower startups and enterprises by implementing cutting-edge strategies and tools, driving efficiency and customer engagement.",
    "From local SEO to advanced analytics, we integrate data-driven approaches to improve visibility, enhance user experience, and foster sustainable business growth.",
    "Our dedicated team in Vietnam provides end-to-end digital marketing solutions, enabling businesses to thrive in competitive markets."
]

const iconBlockTitle = "Our Marketing Services in Saigon"
const icons = [
    {
        name: "computer",
        title: "SEO Services That Drive Traffic",
        text: "Our expert team specializes in SEO strategies tailored to businesses in Saigon, helping you rank higher on search engines, drive organic traffic, and attract more local customers."
    },
    {
        name: "chat",
        title: "Pay-Per-Click Campaigns",
        text: "Boost your visibility instantly with targeted PPC campaigns. We create data-driven ads that maximize ROI and help your business stand out in the competitive Saigon market."
    },
    {
        name: "arising",
        title: "Social Media Marketing",
        text: "Engage your audience on platforms like Facebook, Instagram, and LinkedIn. Our social media strategies are designed to grow your brand’s presence and foster meaningful connections."
    },
    {
        name: "check",
        title: "Content Marketing Solutions",
        text: "Content is king, and we create high-quality, SEO-friendly content that captivates your audience, improves your online presence, and drives conversions in the Saigon market."
    },
    {
        name: "content",
        title: "Data-Driven Marketing Strategies",
        text: "Our data analytics expertise ensures your campaigns are optimized for performance. We track, measure, and refine strategies to deliver measurable results for your business."
    }
]

const cta1 = {
    title: "Empowering Saigon Businesses with Digital Expertise",
    description:
        "At Saigon Digital, we don’t just deliver marketing services; we craft growth stories. Partner with us to transform your digital presence in Vietnam and beyond."
}

const cta2 = {
    title: "Ready to transform your business? Contact the top marketing agency in Saigon today!"
}


const DigitalTranformation = ({ location }) => {
    const context = {
        pageName: "Service | Saigon Marketing Agency",
        pageUri: location.href
    }
    return (
        <Layout context={context}>
            <HeroDigitalTransform
                heroTextTitle={heroTextTitle}
                heroTextSmallText={heroTextSmallText}
                heroData={heroData}
            />
            <ServiceIconBlock title={iconBlockTitle} icons={icons} />
            <TextImageJamStack
                title={cta1.title}
                description={cta1.description}
            />

            <ThreeColumnContent localisationData={localisationData.digitalmarketingAgency} />

            <div className="container mt-10 lg:mt-32">
                <FAQ faqs={faqs.digitalMarketingAgencyFaqs} />
            </div>

            <LatestBlogs />

        </Layout>
    )
}

export default DigitalTranformation

export const Head = () => (
    <>
        <Helmet>
            <SEO
                title="Saigon Digital - Top Marketing Agency in Vietnam"
                description="Discover Saigon Digital, the leading marketing agency in Vietnam. Specializing in SEO, digital marketing, and business growth solutions."
            />
        </Helmet>
    </>
)
