import React from "react"
import Layout from "../../components/layout"
import ServiceIconDigitalTransform from "../../components/ServiceSinglePage/ServiceIcon/ServiceIconDigitalTransform"
import ShowcaseDigitalTransform from "../../components/ServiceSinglePage/Showcase/ShowcaseDigitalTransForm"
import TextImageLight from "../../components/ServiceSinglePage/TextImage/TextImageLight"
import TextImageDark from "../../components/ServiceSinglePage/TextImage/TextImageDark"
import LatestBlogs from "../../components/LatestBlogs/LatestBlogs"
import HeroDigitalTransform from "./../../components/ServiceSinglePage/Hero/Digital/HeroDigitalTransform"
import ServiceIconBlock from "../../components/ServiceSinglePage/ServiceIcon/ServiceIconBlock"
import TextImageJamStack from "../../components/ServiceSinglePage/TextImage/TextImageJamStack"
import ServiceCTA from "../../components/ServiceLanding/CTA/ServiceCTA"
import { SEO } from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import FAQ from "../../components/FAQ/FAQ"
import faqs from "../../data/faq.json"
import { Helmet } from "react-helmet"
import localisationData from '../../data/localisation.json'
import ThreeColumnContent from "../../components/ThreeColumnContent"
const heroTextTitle = "Digital Transformation"
const heroTextSmallText =
    "Digital transformation is simply an umbrella term for any initiative your business takes to introduce new technology to improve business processes."
const ctaTitle = ""
const heroData = [
    "Saigon Digital can help your business grow and scale using innovation and technology to enable digital transformation. We'll work with you to help connect all the moving parts, integrate your systems, centralise your data and create user-centric solutions so you can focus on business.",
    "We work with businesses, startups and agencies who are needing to streamline their efficiency and workflows. Saigon Digital can provide in-depth consultancy and a discovery phase to help understand your current challenges and sticking points, transforming these into long term digital strategies and solutions.",
    "Implementing new technology and frameworks that create value across the business by reducing inefficiencies and evolving the customer experience.",
    "A shortage of skills and time is often cited as the biggest blocker to digital transformation projects progressing. Behind every project, you need dedicated people with awareness and an overview of the technology and analytics required to grow with digital. We can become an extension of your team to guide the project to success."
]
const iconBlockTitle = "Our Digital Transformation services"
const icons = [
    {
        name: "computer",
        title: "Development that delivers results",
        text: "Our in house development team are using innovative technology to build performant, user driven, highly optimised websites and applications."
    },
    {
        name: "chat",
        title: "AI Automation",
        text: "Visitors expect information to be given to them as soon as they land on your website. AI such as Chatbots can help solve this issue around the clock 24/7."
    },
    {
        name: "arising",
        title: "Scalable platforms",
        text: "Systems need to grow with the businesses that they support. If your systems can’t scale, your business can’t scale."
    },
    {
        name: "check",
        title: "Agnostic platforms",
        text: "Your customers and team members want and need access to everything on demand, don’t let the technology limit your business growth."
    },
    {
        name: "content",
        title: "Data & APIs",
        text: "We’ve worked with a wide range of services and third party API’s, connecting the dots to bring business together and become more effective."
    }
]
const cta1 = {
    title: "Battle Tested SSGs",
    description:
        "Digital transformation is never complete, the world of technology is ever changing and developing as we speak. Saigon Digital can help become your long term partner to deliver a strategic road map for your digital vision. Whilst our solutions are tech driven and future proofed, we look to partner and create a long lasting relationship with our clients to build, iterate and scale your business requirements as the digital landscape evolves."
}
const cta2 = {
    title: "Would you like to grow your business with us? Transform your business today, get in touch for a chat."
}

const DigitalTranformation = ({ location }) => {
    const context = {
        pageName: "Service | Digital Transformation",
        pageUri: location.href
    }
    return (
        <Layout context={context}>
            <HeroDigitalTransform
                heroTextTitle={heroTextTitle}
                heroTextSmallText={heroTextSmallText}
                ctaTitle={ctaTitle}
                heroData={heroData}
            />
            <ServiceIconBlock title={iconBlockTitle} icons={icons} />
f
            <TextImageJamStack
                title={cta1.title}
                description={cta1.description}
            >
                <StaticImage
                    alt="digital"
                    src="../../components/ServiceSinglePage/images/digital-text-image.svg"
                />
            </TextImageJamStack>
            <div className="pt-20 md:pt-0">
                <ServiceCTA title={cta2.title} />
            </div>
            <ThreeColumnContent localisationData={localisationData.digitaltransform}/>
            <div className="container mt-10 lg:mt-32">
                <FAQ faqs={faqs.digitalFaqs} />
            </div>
            <LatestBlogs />
        </Layout>
    )
}

export default DigitalTranformation
export const Head = () => (
    <>
        <Helmet>
            <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "FAQPage",
                    mainEntity: [
                        {
                            "@type": "Question",
                            name: "Why choose Saigon Digital for your Digital Transformation?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "Choosing Saigon Digital means partnering with experts who are deeply committed to your success. We bring extensive experience, industry knowledge, and a customer-centric approach to every project, ensuring that your digital transformation journey is smooth, effective, and aligned with your business goals."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "What is Digital Transformation?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "Digital Transformation involves integrating digital technologies across all areas of a business, fundamentally changing how you operate and deliver value to customers in the digital transformation era."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "What Digital Transformation services do you provide?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "As a leading digital transformation agency, we offer a comprehensive range of services, including digital strategy consulting, cloud migration, process automation, and technology integration. Our expertise in digital transformation in Vietnam and beyond ensures that we tailor our services to meet the unique needs of each client."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "How long does a Digital Transformation project typically take?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "The duration of a digital transformation project can vary widely depending on the scope and complexity of the changes required. Typically, projects may span several months to a year. However, as we are a flexible digital transformation agency, we ensure that our timelines are aligned with your business needs, delivering value at every stage of the transformation."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "How do you tailor Digital Transformation services for different industries?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "Digital transformation in Vietnam and globally is not a one-size-fits-all solution. We customise our approach based on the specific challenges and opportunities within each industry. Whether it's financial services, manufacturing, or retail, our digital transformation agency ensures that the solutions we provide are tailored to drive industry-specific outcomes."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "Can your Digital Transformation solutions integrate with our existing systems?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "Yes, our digital transformation services are designed to seamlessly integrate with your existing systems. We understand the importance of maintaining continuity while introducing new digital tools, ensuring a smooth transition that leverages your current infrastructure while embracing new digital transformation opportunities."
                            }
                        },
                        {
                            "@type": "Question",
                            name: "How much does a Digital Transformation project cost?",
                            acceptedAnswer: {
                                "@type": "Answer",
                                text: "The cost of digital transformation varies depending on the complexity and scope of the project. However, it is important to view this as an investment rather than an expense. Our digital transformation agency provides tailored pricing models that reflect the unique requirements of your business, ensuring you maximise the return on your digital transformation opportunities."
                            }
                        }
                    ]
                })}
            </script>
        </Helmet>
        <SEO
            title={
                "Digital Transformation | Growth and Transformation Agency | Saigon Digital"
            }
            description={
                "Digital transformation is simply an umbrella term for any initiative your business takes to introduce new technology to improve business processes."
            }
        />
    </>
)
